.sidebar {
  z-index: 1029;
  margin: 0;
  padding: 0;
  width: 65px;
  background-color: #2a3858;
  position: fixed;
  height: 100%;
  overflow: auto;

  .logo-container {
    border-radius: 10px;
    background-color: #fff;
    margin-left: 9px;
    width: 40px;
    height: 40px;
    padding: 3px;
    margin-top: 20px;

    .link-logo {
      .logo {
        width: 40px;
        height: auto;
      }
    }

    .link-menu {
      margin-top: 20px;
      margin-left: 8px;
      margin-right: 8px;
      color: #fff;
      cursor: pointer;
    }

    .link-menu.settings {
      position: absolute;
      bottom: 40px;
    }
  }
}

.menu-logout,
.menu-language {
  .MuiPaper-root {
    left: 70px !important;
  }
}

@media screen and (max-width: 600px) {
  .sidebar {
    width: 100%;
    position: fixed;
    height: 60px;
    overflow: unset;

    .logo-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-top: 0;
      padding: 10px;
      background-color: unset;

      .link-logo {
        order: 2;
        border-radius: 10px;
        background-color: #fff;
        margin-left: 16px;
        height: 40px;
      }

      .link-menu {
        margin-top: 0;
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
      }

      .menu-stack {
        display: flex;
        justify-content: 'flex-end';
        flex-direction: row-reverse;
        width: 100%;
        align-items: center;
        order: 3;

        .link-menu {
          margin-right: 8px;
        }

        .link-menu.settings {
          position: static;
          bottom: auto;
        }
      }
    }
  }

  .menu-logout,
  .menu-language {
    .MuiPaper-root {
      left: unset !important;
      top: 61px !important;
      right: 15px;
    }
  }
}
