$breakpoint-tablet: 768px;

.welcome-title {
  margin-bottom: 30px !important;
  @media (min-width: $breakpoint-tablet) {
    margin-top: 100px !important;
  }
}

.login-form, .go-back-button-container {
  @media (min-width: $breakpoint-tablet) {
    padding-right: 80px !important;
  }
}

.go-back-button-container {
  margin-top: 1rem;
}
