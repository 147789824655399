.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiCardContent-root:last-child {
  padding-bottom: 12px !important;
}

.MuiDialogContent-root:first-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiFormControl-root {
  margin-bottom: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Futura';
  src: url(./fonts/futura_light.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url(./fonts/futura_heavy.ttf);
  font-weight: bold;
  font-display: swap;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2a3858 #9b9da0;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #494949;
  border-radius: 20px;
  border: 0 solid;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  display: none;
}

.MuiAutocomplete-popupIndicator,
.MuiAutocomplete-popupIndicator.MuiAutocomplete-popupIndicatorOpen {
  margin-right: 6px !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.2) !important;
}
