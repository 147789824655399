@font-face {
  font-family: 'Futura';
  src: url(../../fonts/futura_light.ttf);
  font-display: swap
}

@font-face {
  font-family: 'Futura';
  src: url(../../fonts/futura_heavy.ttf);
  font-weight: bold;
  font-display: swap
}

.header {
  height: 85px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .buenaFrutaBold {
    font-family: 'Futura';
    letter-spacing: -1px;
    font-weight: 600;
  }

  .buenaFruta {
    font-family: 'Futura';
  }

  .logo-container {
    margin-left: 50px;
    height: 100%;
    width: 50px;
    padding: 5px;

    .logo {
      width: 75px;
      height: 75px;
    }
  }

  .header-title {
    margin-left: 40px;
    margin-top: 30px;
    font-size: 30px;
  }
}

  